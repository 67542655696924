const CountriesMapping = require('~/lib/countries-mapping/index.mapping')

export const useDetectCountryCodeFromTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const getFormat = CountriesMapping.getTimezone(timeZone)

  return {
    aliasOf: getFormat.aliasOf,
    countries: getFormat.countries,
    deprecated: getFormat.deprecated,
    dstOffset: getFormat.dstOffset,
    dstOffsetStr: getFormat.dstOffsetStr,
    name: getFormat.name,
    utcOffset: getFormat.utcOffset,
    utcOffsetStr: getFormat.utcOffsetStr,
    countryCode: (getFormat?.countries?.[0] || '').toLowerCase()
  }
}
